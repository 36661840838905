import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { useSearchParams } from "react-router-dom";

import SanityCubit from "src/state/SanityCubit/SanityCubit";
import { useBloc } from "src/state/state";
import type { CmsCareTeamMember } from "src/types/sanitySchema";
import AsyncContent from "src/ui/components/AsyncContent/AsyncContent";
import ResponsiveImage from "src/ui/components/ResponsiveImage/ResponsiveImage";
import SanityBlockContent from "src/ui/components/SanityBlockContent/SanityBlockContent";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import InDialog from "../../InDialog/InDialog";
import Translate from "src/ui/components/Translate/Translate";
import translate from "src/lib/translate";
import { AppQueryPopupsController } from "../../AppQueryPopups/AppQueryPopupsBloc";

const Image = styled.div`
  margin: 1em 0;
  width: 100%;
  aspect-ratio: 16/9;
  position: relative;
`;

const Content = styled.div`
  padding: 0 0 0rem;
`;

const ChatCareTeamDialog: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const [query] = useSearchParams();
  const id = query.get("id") ?? "";
  const navigate = useGoToOrBack();
  const [, { fetchCareTeamDetails }] = useBloc(SanityCubit, {
    create: () => new SanityCubit<CmsCareTeamMember>()
  });

  const details = fetchCareTeamDetails(id);

  const onClose = () => {
    AppQueryPopupsController.closePopup();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  if (!id || !details || details.error) {
    return null;
  }

  const {
    name,
    description,
    resolvedPortrait,
    portrait,
    certificationsAndAwards,
    dcsReason,
    aboutMe,
    funFacts
  } = details;

  return (
    <InDialog
      popup
      title={translate("careTeamMember")}
      returnUrl={returnUrl}
      onClose={onClose}
    >
      <Content>
        <AsyncContent check={[name]} height="1.6rem" width="60%">
          <h5 className="as-strong">{name}</h5>
        </AsyncContent>
        <AsyncContent width="80%">{description}</AsyncContent>
        {portrait && (
          <AsyncContent check={[resolvedPortrait]} height="20rem" width="100%">
            <Image>
              <ResponsiveImage
                fillContainer
                image={resolvedPortrait}
                alt={name}
              />
            </Image>
          </AsyncContent>
        )}

        <AsyncContent
          check={[aboutMe !== undefined]}
          width="100%"
          height="20rem"
        >
          {aboutMe && aboutMe.length > 0 && (
            <>
              <p className="as-strong">
                <Translate msg="profile.aboutMe" />
              </p>
              <SanityBlockContent blocks={aboutMe} />
            </>
          )}
        </AsyncContent>

        <AsyncContent
          check={[certificationsAndAwards !== undefined]}
          width="100%"
          height="20rem"
        >
          {certificationsAndAwards && certificationsAndAwards.length > 0 && (
            <>
              <p className="as-strong">
                <Translate msg="profile.certifications" />
              </p>
              <SanityBlockContent blocks={certificationsAndAwards} />
            </>
          )}
        </AsyncContent>

        <AsyncContent
          check={[dcsReason !== undefined]}
          width="100%"
          height="20rem"
        >
          {dcsReason && dcsReason.length > 0 && (
            <>
              <p className="as-strong">
                <Translate msg="profile.motivation" />
              </p>
              <SanityBlockContent blocks={dcsReason} />
            </>
          )}
        </AsyncContent>

        <AsyncContent
          check={[funFacts !== undefined]}
          width="100%"
          height="20rem"
        >
          {funFacts && funFacts.length > 0 && (
            <>
              <p className="as-strong">
                {" "}
                <Translate msg="profile.funFacts" />
              </p>
              <SanityBlockContent blocks={funFacts} />
            </>
          )}
        </AsyncContent>
        <p></p>
      </Content>
    </InDialog>
  );
};

export default ChatCareTeamDialog;
